<template>
  <div v-bind="$attrs">
    <div ref="countUp" v-if="numType == 'num'" class="wb-100 hb-100 center">
      {{ num || num == 0 ? num : "---" }}
    </div>

    <div v-else-if="numType == 'time'" class="wb-100 hb-100 center">
      {{ num || "---" }}
    </div>
  </div>
</template>

<script>
import { CountUp } from "countup.js";
export default {
  props: {
    value: [Number, String],
    animation_time: {
      type: Number,
      default: 1,
    },
    numType: {
      type: String,
      default: "num",
    },
    separator: {
      type: String,
      default: "",
    },
  },
  name: "ChangeNum",
  data() {
    return {
      screenNum: this.value,
    };
  },
  computed: {
    num() {
      const { value, numType, screenNum } = this;
      let record = "";
      switch (numType) {
        case "time":
          let s = screenNum < 0 ? 0 : Math.floor(screenNum % 60);
          let m = screenNum < 0 ? 0 : Math.floor((screenNum / 60) % 60);
          record = screenNum
            ? (m < 10 ? "0" + m : m) + "'" + (s < 10 ? "0" + s : s) + "''"
            : "00'00''";
          break;
        default:
          record = value < 0 ? 0 : value;
          break;
      }
      return record;
    },
  },
  methods: {
    async sleep(millisecond) {
      return new Promise(function (reslove) {
        setTimeout(reslove, millisecond);
      });
    },
  },
  watch: {
    async value(newValue, oldValue) {
      const countUp = new CountUp(this.$refs.countUp, newValue, {
        startVal: oldValue,
        duration: this.animation_time,
        separator: this.separator,
      });

      if (!countUp.error) {
        countUp.start();
      } else {
        // console.error(countUp.error);
      }

      if (this.numType == "time") {
        const totalTime = this.animation_time * 1000;
        const times = 20; //数字变化次数
        const intervalTime = Math.floor(totalTime / times);
        // 每次变化差值
        const distance = (newValue - oldValue) / times;
        for (let index = 0; index < times; index++) {
          this.screenNum += distance;
          await this.sleep(intervalTime);
        }
      }
    },
  },
};
</script>
