const Layout = () => import("../components/layout.vue");
const bcxj = () => import("../views/deepBlueScreen/bcxj.vue");

export default [
  {
    path: "/deepBlue",
    name: "deepBlue",
    component: Layout,
    children: [
      {
        path: "bcxj",
        name: "bcxj",
        component: bcxj,
      },
    ],
  },
];
