/** @name 调用摄像头 */
export function getUserMedia(box, success, error) {
  //优先使用前置摄像头（如果有的话）：{ video: { facingMode: "user" } }
  //制使用后置摄像头：{ video: { facingMode: { exact: "environment" } } }
  // video: {
  //    width: { min: 1024, ideal: 1280, max: 1920 },
  //    height: { min: 776, ideal: 720, max: 1080 }
  // }
  //ideal（应用最理想的）值
  const constraints = {
    audio: false,
    video: {
      width: box.width,
      height: box.height,
      facingMode: "environment",
    },
  };
  if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
    // 最新的标准API
    navigator.mediaDevices.getUserMedia(constraints).then(success).catch(error);
  } else if (navigator.webkitGetUserMedia) {
    // webkit核心浏览器
    navigator.webkitGetUserMedia(constraints, success, error);
  } else if (navigator.mozGetUserMedia) {
    // firfox浏览器
    navigator.mozGetUserMedia(constraints, success, error);
  } else if (navigator.getUserMedia) {
    // 旧版API
    navigator.getUserMedia(constraints, success, error);
  } else {
    console.log("========1");
  }
}

export function calculate(node, KNNArgs, N, Threshold = 0.1) {
  var distances = [];
  var len = KNNArgs.length;
  var node_len = node.length;

  for (var i = 0; i < len; i++) {
    var distance = 0;
    for (var j = 0; j < node_len; j++) {
      distance += Math.pow(node[j] - KNNArgs[i][j], 2);
    }
    //console.log('distance:',distance);
    if (distance <= Threshold) {
      distances.push([distance, KNNArgs[i].slice(-1)[0]]);
    }
  }
  //console.log('dis_len:',distances.length);
  distances.sort(function (x, y) {
    return x[1] - y[1];
  });
  var label_count = {};
  var max_label = [-1, 0];
  var max_distence = 0;
  for (var i = 0; i < Math.min(N, distances.length); i++) {
    var distance = distances[i][0];
    var label = distances[i][1];
    if (label_count.hasOwnProperty(label)) {
      label_count[label] += 1;
    } else {
      label_count[label] = 1;
    }

    if (label_count[label] > max_label[1]) {
      max_label = [label, label_count[label]];
      max_distence = distance;
    }
  }

  // console.log(distance,'distance===');
  // console.log(max_label,'max_label')

  return {
    label: max_label[0],
    distance,
  };
}

export function processingResults(results) {
  const defaultPose = new Array(24).fill(0.0);
  const select_index = [11, 12, 23, 24, 25, 26, 27, 28, 13, 14, 15, 16];
  if (!results.poseLandmarks) {
    return defaultPose;
  }

  var detect = results.poseLandmarks;
  var xyPose = [];
  select_index.forEach((item) => {
    xyPose.unshift(detect[item].x);
    xyPose.unshift(detect[item].y);
  });

  var odd_num = xyPose.filter((item, index) => index % 2 === 0);
  var even_num = xyPose.filter((item, index) => index % 2 !== 0);

  var minxPose = Math.min.apply(null, odd_num);
  var maxxPose = Math.max.apply(null, odd_num);

  var minyPose = Math.min.apply(null, even_num);
  var maxyPose = Math.max.apply(null, even_num);

  xyPose.forEach((item, i) => {
    if (i % 2 == 0) {
      xyPose[i] = (xyPose[i] - minxPose) / (maxxPose - minxPose);
    } else {
      xyPose[i] = (xyPose[i] - minyPose) / (maxyPose - minyPose);
    }
  });
  return xyPose;
}

export function rand(m, n) {
  return Math.ceil(Math.random() * (n - m + 1) + m - 1);
}

//  计算一个数组里面 每一个值出现了多少次
export function getNumCount(arr) {
  const totalObj = arr.reduce((pre, next) => {
    if (pre[next]) {
      pre[next]++;
    } else {
      pre[next] = 1;
    }
    return pre;
  }, {});
  return totalObj;
}

// 数组的累计求和
export function summation(arr) {
  return arr.reduce(function (prev, curr) {
    return prev + curr;
  });
}

/**
 * 下载canves为图片
 * @param {url} url
 * @param {文件名} filename
 */
export function downloadCanves(url, filename = "下载") {
  // let base64 = canvas.toDataURL("image/png");
  const a = document.createElement("a"); // 动态创建a标签，防止下载大文件时，用户没看到下载提示连续点击
  a.href = url;
  a.download = filename;
  a.click();
  window.URL.revokeObjectURL(url);
}

// 累加
export function sumKey(arr, key) {
  return arr.reduce((a, b) => a + b[key], 0);
}
