d
<template>
  <div class="load-contain">
    <div class="load-head align-center row-center wb-100 relative">
      运动负荷监测系统
      <img
        :src="imageUrl + 'screen_021.png'"
        alt=""
        class="control_icon"
        @click="handleBack"
      />
    </div>
    <div class="load-content align-center flex-wrap">
      <div class="rank-contain" v-for="(item, index) in rateList" :key="index">
        <!-- <template v-if="item.isOffline"> -->
        <template v-if="true">
          <div class="wb-100 align-center space-between rank-head">
            <div class="num SC-Regular">{{ item.number || "---" }}</div>
            <div class="name SC-Regular">{{ item.name || "---" }}</div>
          </div>
          <div class="rank-content wb-100" :style="setBoxBg(item, index)">
            <div class="wb-100 hb-54">
              <div class="align-center wb-100 row-center">
                <div class="fz-36 mr-5 t-w">
                  <number-animation
                    :value="item.heart_avg"
                    :animation_time="1"
                    numType="num"
                    separator=","
                    class="SC-Medium"
                  />
                </div>
                <div class="align-center column">
                  <img
                    :src="imageUrl + 'screen_023.png'"
                    alt=""
                    class="w-15 h-14"
                  />
                  <div class="t-w fz-14 SC-Regular">bpm</div>
                </div>
              </div>
              <div class="wb-100 row-center fz-16 SC-Regular mt-6">
                实时心率
              </div>
            </div>
            <div class="wb-100 hb-40 relative">
              <div class="wb-100 hb-100 rank-bg"></div>
              <div class="rank-animation"></div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="wb-100 align-center space-between rank-head">
            <div class="num SC-Regular">---</div>
            <div class="name SC-Regular">---</div>
          </div>
          <div class="rank-content-no center column">
            <div class="align-center">
              <div class="fz-36 mr-5 SC-Regular">---</div>
              <div class="align-center column">
                <img
                  :src="imageUrl + 'screen_022.png'"
                  alt=""
                  class="w-15 h-14"
                />
                <div class="fz-14 SC-Regular">bpm</div>
              </div>
            </div>
            <div class="wb-100 row-center fz-16 SC-Regular mt-6">实时心率</div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { imageUrl } from "@/common/common.js";
import numberAnimation from "@/components/numberAnimation";
export default {
  components: {
    numberAnimation,
  },
  data() {
    return {
      imageUrl,
      school_id: "",
      class_id: "",
      total: "",
      schedule_id: "",
      rateList: [],
      timeInter: null,
    };
  },
  computed: {
    setBoxBg() {
      return (reocrd, index) => {
        const offlineColor = "rgba(227, 227, 227, 1)";
        const bgColor = {
          0: "linear-gradient( 180deg, #51C17C 0%, #51C17C 100%)",
          1: "rgba(255, 168, 95, 1)",
          2: "rgba(255, 133, 97, 1)",
        };

        let select = 0;
        if (reocrd.heart_avg < 120) {
          select = 0;
        } else if (reocrd.heart_avg > 180) {
          select = 2;
        } else {
          select = 1;
        }

        return {
          // background: reocrd.isOffline ? bgColor[select] : offlineColor,
          background: bgColor[select],
        };
      };
    },
  },
  created() {
    this.getQuery();
    this.get_data();
    clearInterval(this.timeInter);
    this.timeInter = setInterval(() => {
      this.get_data();
    }, 5000);
  },
  methods: {
    getQuery() {
      const { school_id, class_id, total, schedule_id } = this.$route.query;
      this.school_id = school_id ? school_id : 53;
      this.class_id = class_id ? class_id : 808;
      this.studentsNumber = total ? total : 1191;
      this.schedule_id = schedule_id ? schedule_id : 224446;
    },
    async get_data() {
      const { school_id } = this;
      const res = await this.axios.get("courses/polling/" + school_id);
      if (res.data.code === 1) {
        const { data } = res.data;

        // this.studentList = data.students.sort(
        //   (a, b) => b.heart_avg - a.heart_avg
        // );

        // this.rateList = data.students.map((item) => {
        //   return {
        //     ...item,
        //     isOffline: false,
        //   };
        // });

        this.rateList = data.students;
      }
    },
    handleBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss">
.load-contain {
  width: 100%;
  height: 100%;
  background: radial-gradient(rgba(10, 33, 47, 0.5) 0%, #0a212f 100%);
  .load-head {
    width: 100%;
    // height: 1rem;
    height: 9.25%;
    color: #fff;
    font-size: 0.38rem;
    position: relative;
    .control_icon {
      position: absolute;
      top: 50%;
      transform: translateY(-80%);
      width: 0.3rem;
      height: 0.3rem;
      cursor: pointer;
      right: 0.3rem;
    }
  }

  .SC-Regular {
    font-family: "www.alltoall.net_notosanssc-6._Rh2tKdUFz8", sans-serif;
  }

  .SC-Medium {
    font-family: "D-DIN-PRO-500-Medium", sans-serif;
  }

  .load-content {
    width: 100%;
    height: 90.75%;
    padding: 0.16rem 0.1rem 0rem;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
    .rank-contain {
      margin: 0.04rem;
      width: 1.8rem;
      height: 1.82rem;
      border-radius: 0.08rem;
      overflow: hidden;
      .rank-head {
        background-color: rgba(255, 255, 255, 1);
        font-size: 0.2rem;
        color: rgba(10, 33, 47, 1);
        padding: 0 0.1rem;
        height: 22%;
      }
      .rank-content {
        width: 100%;
        height: 78%;
        .rank-bg {
          background: url("https://sisheng-resource.oss-cn-shenzhen.aliyuncs.com/prod/pic/big_screen/screen_032.png")
            no-repeat;
          background-size: 100% 100%;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 10;
        }
        .rank-animation {
          background: url("https://sisheng-resource.oss-cn-shenzhen.aliyuncs.com/prod/pic/big_screen/screen_033.png")
            no-repeat;
          background-size: 1.8rem 100%;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 20;
          height: 100%;
          width: 10%;
          animation: rank 1.5s linear infinite;
        }
      }

      .rank-content-no {
        width: 100%;
        height: 78%;
        background-color: rgba(227, 227, 227, 1);
      }
    }
  }
}

@keyframes rank {
  form {
    transform: translateX(0);
    background-position-x: 0%;
  }
  to {
    transform: translateX(1.62rem);
    background-position-x: 100%;
  }
}
</style>
